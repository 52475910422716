// 选择设备能力类型
<template>

    <div class="selectHardwarePower">
        <el-select v-model="power"
                   :filterable="filterable"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :clearable="clearable"
                   @change="change">
            <el-option v-for="item in options"
                       :key="returnType === 'String' ? item.value : item"
                       :label="item.label"
                       :value="returnType === 'String' ? item.value : item">
            </el-option>
        </el-select>
    </div>

</template>
<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "selectHardwarePower",
    components: {},

    props: {
        // v-model绑定的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // 下拉搜索条件
        searchData: {
            type: Object,
            default: null,
        },
        // 返回值类型 ["String", "Object"]
        returnType: {
            type: String,
            default: "String",
        },
    },

    data() {
        return {
            power: "",
            options: [], // 选项
        };
    },

    created() {},

    mounted() {
        // 获取设备类型下拉数据
        this.getOpts();
    },

    methods: {
        // 获取设备类型下拉数据
        getOpts() {
            commonAPI.getSelectByCode("CapacityType").then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.options = opt;
            });
        },
        // 触发change事件
        change() {
            this.$emit("change");
        },
    },

    computed: {},

    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.power = newValue;
            },
        },
        power() {
            this.$emit("input", this.power);
        },
    },
};
</script>